@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.2/font/bootstrap-icons.css);

html,
body {
   margin: 0;
   padding: 0;
}

.material-symbols-outlined {
  color: #6c3373;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

/* .fillmaterial-symbols-outlined{
  color: #6c3373;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
} */

.mediumSpan {
  font-size: 1.8rem;
}

.largeSpan {
  font-size: 2.1875rem;
}

.hugeSpan {
  font-size: 2.5rem;
}

.container-respuestas {
  overflow: auto;
  /* Other styles */
}

.iconArgumentapp {
  color: #6c3373;
  font-size: 2rem;
}

.container-respuestas::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
  /* Other scrollbar styles */
}

.container-respuestas::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Other thumb styles */
}

.container-respuestas::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Other track styles */
}

.container-respuestas.hide-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

body {
  padding: 0;
  min-width: fit-content;
  width: 100%;
  background: linear-gradient(45deg,
      #DCE7FE 0%,
      #F8F3FF 100%);
  margin: 0px;
  overflow: hidden;
}

#root {
  transform-origin: top left;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

a {
  color: #c493cf;
  font-style: italic;
}

a:hover {
  color: #6c3373;
}

a:visited {
  color: #c493cf;
}

.page-wrap {
  position: fixed;
  top: 20px;
  right: 20px;
  left: 20px;
  bottom: 20px;
  /* background: white; */
  background-color: #F9F8FE !important;
  border-radius: 18px;
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  /* overflow-y: calc(100% - 50px); */
}

.page-wrapBlank {
  position: fixed;
  top: 20px;
  right: 20px;
  left: 20px;
  bottom: 20px;
  /*background-color: #ffffffbd !important;*/
  padding: 0px 0px 0px 0px;
  /*border-radius: 18px;*/
  overflow-y: hidden;
  overflow-x: hidden;
  /* overflow-y: calc(100% - 50px); */
}

@media (max-width: 350px) {
  .page-wrap {
    top: 10px;
    right: 10px;
    left: 10px;
    bottom: 10px;
  }
}

.zoomNavegator {
  transform: scale(1);
  transform-origin: center;
}

.zoomNavegator .componenteInterior {
  transform: scale(1);
  transform-origin: center;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

.login-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  min-height: 100vh !important;
  background: #fff;
}

.buttonSelectedOptionLogin {
  width: 100% !important;
}

.buttonNoSelectedOptionLogin {
  width: 100% !important;
  box-shadow: none !important;
}

.argumentapp-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-container {
  width: 100%;
  /* min-width: 1000px; */
  padding-top: 120px;
  margin-top: 30px;
  /* background-color: #ffffffbd !important; */
  height: auto;
  max-height: 100%;
  overflow-y: auto;
  margin-right: 10px;
}

.main-container-sinMenu {
  position: absolute;
  width: 100%;
  padding-top: 100px;
  padding-left: 200px;
  padding-right: 100px;
  background-color: #ffffffbd !important;
  min-height: 100%;
  left: 0;
}

.main-container .contenido {
  margin: 30px 30px 10px 30px;
  color: #3e3e3e;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.main-container .MuiTypography-titulo {
  margin-left: 30px;
}

.grupo-botones-config {
  display: flex;
  flex-direction: column;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /* display: none; */
}

*::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 25px;
}

*::-webkit-scrollbar-thumb {
  background-color: #3e3e3e;
  border-radius: 12px;
}

.centrarFlexRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.centrarFlexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.centrarFlexRowJustCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.centrarGrid {
  display: grid;
  place-items: center;
}

.fluidWidth100 {
  width: 100%;
}

.fluidHeight100 {
  height: 100%;
}

.fluidHeightAuto {
  height: auto;
}

.padding10 {
  padding: 10px;
}

.padding20 {
  padding: 20px;
}

.padding10 {
  padding: 10px;
}

.paddingTop0 {
  padding-top: 0px;
}

.encabezadoModal {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.textInformativo {
  width: 500px;
  display: grid;
  place-items: center;
}

.AccordionSyleArgumentapp {
  height: auto;
  width: auto;
  max-width: 700px;
  border-radius: 18px;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 18px;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
}

.ListaDiapoAcciones {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 15px;
  padding: 20px;
  overflow-y: auto;
}

.gapDisplay {
  gap: 10px;
}

.diapoVistaPreviaSubir {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;
}

.listarAccionesDiapositiva {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 5px;
}

.botonesPersonalizadosDiapositiva {
  width: 100%;
  max-height: 50px;
  min-height: 50px;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  background-color: #9c5fa2;
}

.botonesPersonalizadosDiapositiva:hover {
  background-color: #6d4271;
}

.styleDiapositiva {
  border-radius: 24px;
  max-width: 1030px;
  min-height: 610px;
  max-height: 650px;
  background: #fff;
}

.styleDiapositivaNew {
  /* border-radius: 25px 25px 0px 0px; */
  border-radius: 25px;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  max-width: 1030px;
  min-height: 630px;
  max-height: 650px;
  background: #fff;
}

.contenidoPrevioHTML {
  padding: 10px 25px 0px;
  height: 610px;
  overflow: hidden;
  margin-bottom: 20px;
}

.contentPreviewDiapositiva {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: -80px;
  padding: 10px;
  padding-bottom: 70px;
}

.contentPreviewDiapositiva .gridEditDiapo {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  align-items: flex-end;
}

.flexRowAligItemCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gridModalArgumentappAcciones {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.textDatosClase {
  display: -webkit-box;
  overflow: hidden;
  margin-bottom: 20px;
}

.progresionHipotetica1 {
  border-radius: 18px 0px 0px 18px;
  background: #6C3373;
  width: 300px;
  min-height: 70px;
  padding: 8px;
  resize: none;
  border-color: #6C3373;
  font-size: 14px;
  font-style: italic;
  font-family: 'Poppins' !important;
}

.progresionHipotetica1:focus {
  outline: none;
}

.progresionHipotetica1::placeholder {
  color: white;
  opacity: 0.9;
  font-weight: 500;
  font-size: 14px;
}

.TextAreaAutoMUI {
  font-weight: 500;
  font-family: 'Poppins' !important;
  color: #3e3e3e;
  border-radius: 10px;
  font-size: 13px;
  resize: none;
  overflow: auto;
  overflow-y: auto;
  padding: 10px;
}

.TextAreaAutoMUI::placeholder {
  opacity: 0.5;
  font-weight: 500;

}

.progresionHipotetica2 {
  border-color: #6C3373;
  width: 300px;
  background-color: white;
  min-height: 70px;
  border-radius: 0px 18px 18px 0px;
  border: 1.4px solid #6C3373;
  padding: 8px;
  resize: none;
  font-size: 14px;
  font-family: 'Poppins' !important;
  font-style: italic;
}

.progresionHipotetica2::placeholder {
  color: #9c5fa2;
  opacity: 0.5;
  font-weight: 500;
  font-size: 14px;
}

.progresionHipotetica2:focus {
  outline: none;
}

/* ...................Cambios en la interfaz....................... */

.vistaClaseContenido {
  width: 82%;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  padding-left: 20px;
  min-width: 500px;
}

.vistaClaseContenido .scrollContenido {
  /* height: 68vh; */
  overflow-y: auto;
  padding: 10px;
  padding-right: 20px;
  min-height: 500px;
}

.boxVistasRecientemente {
  min-height: 200px;
  max-height: 290px;
  padding: 30px;
  overflow-y: auto;
  border-radius: 18px;
  background: #9c5fa229;
}

.boxVistasRecientemente .itemUnidad {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  padding-left: 0px;
  flex-wrap: wrap;
}

.styleCardArgumentapp {
  display: flex;
  border-width: 1px;
  padding: 15px;
  height: 180px;
  width: 260px;
  border-radius: 18px !important;
  cursor: pointer;
}

.styleCardArgumentapp .imageCaratula {
  width: 85px;
  border-radius: 16px;
}

.styleCardArgumentapp .boxContenido {
  padding-right: 0px;
  padding-top: 0px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0px !important;
}

.styleCardArgumentapp .boxContenidoClase {
  padding-right: 0px;
  padding-top: 0px;
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.h4Diapo {
  width: 230px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  margin-left: 0px;
  color: #3e3e3e;
  font-family: "Poppins";
}

.imageCursoModal {
  width: 213px;
  height: 248px;
  border-radius: 16px;
  background-size: cover !important;
  background-position: center !important;
  display: flex;
  flex-direction: row;
}

.contenidoModalScroll {
  max-height: 65vh;
  overflow: auto;
}

.classEditorHtml {
  max-width: 1250px;
}

.styleDiapositivaProf {
  zoom: 80%;
}

.styleDiapositivaAnexos {
  zoom: 95%;
}

.divEncabezadoModal {
  width: 40px;
}

.circuleDivHome {
  margin-top: -120px !important;
  zoom: 130%;
  z-index: 1;
  margin: 30px;
}

/* .circuleDivHome::after {
  position: absolute;
  content: " ";
  z-index: -1;
  float: right;
  width: 300px;
  height: 285px;
  shape-outside: circle(50% at 30%);
  clip-path: circle(48% at 70%);
  background: #F4F2F6;
} */

.tituloHome {
  font-weight: 500;
}

.subtitleHome {
  font-weight: 500 !important;
  font-size: 16 !important;
  width: 70%;
  margin-top: 20 !important;
}

.imageHome {
  width: 340px;
  margin-right: -60px;
}

@media (max-width: 1600px) {
  .styleDiapositiva {
    zoom: 90%;
  }

  .tituloHome {
    font-size: 24px !important;
  }

  .imageHome {
    width: 300px;
  }

  .circuleDivHome {
    margin-top: -80px !important;
  }

  .styleDiapositivaProf {
    zoom: 75%;
  }

  .styleDiapositivaAnexos {
    zoom: 90%;
  }
}

@media (max-width: 1500px) {
  .zoomNavegator {
    transform: scale(0.95);
    transform-origin: center;
  }

  .zoomNavegator .componenteInterior {
    transform: scale(1.05);
    transform-origin: center;
  }

  .styleDiapositiva {
    zoom: 80%;
  }

  .styleDiapositivaProf {
    zoom: 70%;
  }

  .styleDiapositivaAnexos {
    zoom: 85%;
  }
}

@media (max-width: 1400px) {
  .zoomNavegator {
    transform: scale(0.9);
    transform-origin: center;
  }

  .zoomNavegator .componenteInterior {
    transform: scale(1.1);
    transform-origin: center;
  }

  .styleDiapositiva {
    zoom: 70%;
  }

  .tituloHome {
    font-size: 20px !important;
  }

  .imageHome {
    width: 260px;
  }

  .subtitleHome {
    font-size: 13 !important;
    width: 90%;
  }

  .circuleDivHome {
    margin-top: -40px !important;
  }

  .styleDiapositivaProf {
    zoom: 70%;
  }

  .styleDiapositivaAnexos {
    zoom: 80%;
  }
}

@media (max-width: 1300px) {
  .zoomNavegator {
    transform: scale(0.85);
    transform-origin: center;
  }

  .zoomNavegator .componenteInterior {
    transform: scale(1.15);
    transform-origin: center;
  }

  .styleDiapositiva {
    zoom: 60%;
  }

  .styleDiapositivaProf {
    zoom: 65%;
  }

  .styleDiapositivaAnexos {
    zoom: 75%;
  }
}

@media (max-width: 1200px) {
  .styleDiapositiva {
    zoom: 70%;
  }

  .styleDiapositivaProf {
    zoom: 65%;
  }

  .tituloHome {
    font-size: 32px !important;
  }

  .imageHome {
    width: 340px;
  }

  .subtitleHome {
    font-size: 16 !important;
    width: 70%;
  }

  .circuleDivHome {
    margin-top: -120px !important;
  }
}

@media (max-width: 1100px) {
  .styleDiapositiva {
    zoom: 60%;
  }
}

@media (max-width: 970px) {
  .styleDiapositiva {
    zoom: 54%;
  }

  .tituloHome {
    font-size: 26px !important;
  }

  .imageHome {
    width: 280px;
  }

  .circuleDivHome {
    margin-top: -80px !important;
  }
}

@media (max-width: 880px) {
  .styleDiapositiva {
    zoom: 50%;
    overflow-x: auto;
    width: auto;
  }

  .tituloHome {
    font-size: 20px !important;
  }

  .imageHome {
    width: 250px;
  }

  .subtitleHome {
    font-size: 13 !important;
    width: 100%;
  }
}

@media (max-width: 660px) {
  .imageHome {
    width: 250px;
    display: none;
  }

  .circuleDivHome {
    display: none;
  }
}

@media (max-height: 640px) {
  .typographyDiapodefault {
    padding-left: 10px !important;
    padding-right: 10px !important;
    text-align: center;
    padding: 10px;
    font-size: 20px !important;
    max-width: max-content;
    gap: 10px;
  }

  .imageLogoDefault {
    width: 42vw;
    margin-bottom: 10px;
  }
}

@media (max-height: 490px) {
  .typographyDiapodefault {
    padding-left: 10px !important;
    padding-right: 10px !important;
    text-align: center;
    padding: 10px;
    font-size: 14px !important;
    max-width: max-content;
  }

  .imageLogoDefault {
    width: 25vw;
    margin-bottom: 10px;
  }
}

.cardPizarra {

  padding: 30px;
  width: 95vw;
  height: 95vh;
  border-radius: 25px !important;
}

.hoverable {
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 10px;
}

.hoverable:hover {
  background-color: #9c5fa2;
}

.hoverable:hover .MuiListItemText-primary {
  color: white;
}

.dragging {
  background-color: #e0e0e0;
  color: #666;
  cursor: grabbing;
  z-index: 5000;
}

.cortarDoslineas {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.cortarUnalineas {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}

.cardEstudiante {
  padding: 20px;
  border-radius: 18px !important;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
}

.contentPredeterminada {
  flex-direction: column;
  display: flex;
  padding: 0px;
}

.contentPredeterminada .imagen {
  height: 25vh !important;
}

.typographyDiapodefault {
  padding-left: 50px !important;
  padding-right: 50px !important;
  text-align: center;
  padding: 40px;
  max-width: 700px;
}

.__floater__arrow {
  display: none;
  visibility: hidden;
}

.buttonHoverRosa:hover {
  background-color: #EBDFEC !important;
  color: #9c5fa2 !important;
}

.BoxModalArgumentapp-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 350px;
  width: 420px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  gap: 15px;
}